@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,800&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}

.background {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 40% auto;
  background-position: left;
  background-repeat: no-repeat;
}

.mainContainer {
  padding: 30px;
  padding-bottom: 40px;
  background: white;
  width: 750px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  box-shadow: 8px 8px 4px 0px #21252940;
  margin-left: 25%;
}

.termsTxt {
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 400;
  color: #002c77;
}

.termsTxtLink {
  text-decoration: underline;
  color: #002c77 !important;
}

.accountTtx {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #002c77;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.subheader {
  font-weight: 800;
  font-style: italic;
  color: #002c77;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 10px;
}

.subheader2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #002c77;
}

.centered-box {
  background-color: rgb(65 62 62 / 84%);
  width: 806px;
  min-height: 767px;
  border-radius: 10px;
  color: white;
}

.text-body {
  color: #002c77;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.text-body2 {
  color: #002c77;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.minsBtn {
  color: #002c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #f2f0ec;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 54px;
}

.input {
  color: #4871b9;
  font-style: italic;
  line-height: 27px;
  font-size: 18px;
}

.emptyInput::placeholder {
  color: #4871b9;
  font-style: italic;
}

input:not(:placeholder-shown) {
  font-style: normal;
  color: #002c77;
}

.mins-txt {
  color: #6083bf !important;
  line-height: 27px;
  font-size: 18px;
}

.text-body-xs {
  color: #002c77;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
}

.minBtnSelected {
  border: 1px solid #002c77;
}

.finalContainer {
  background: #00286b;
  color: white;
  padding: 24px;
  border-radius: 16px;
  padding-left: 130px;
  width: 85%;
  margin: auto;
  position: relative;
}

.emptyInput {
  border: 1px solid rgb(233, 35, 35) !important;
}

.phone-img {
  position: absolute;
  left: -30px;
  top: -20px;
  width: 122px;
  height: 249px;
}

.finalSubheader {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.finalTxt {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.finalBtn {
  color: #002c77 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  background: white !important;
}

.subheader3 {
  color: #002c77;
  font-size: 22px;
  font-weight: 800;
  line-height: 33px;
  font-style: italic;
  text-align: center;
}

.selectStyle {
  color: #002c77 !important;
}

.textareaStyle {
  min-height: 100px;
  max-height: 200px;
  height: auto;
}

.form-label {
  color: #002c77;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
}

.box-header {
  padding: 20px;
  text-align: center;
  background-color: rgb(23 139 196 / 87%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.box-body {
  padding: 30px;
  font-weight: 400;
  overflow: auto;
  max-height: 525px;
}

.tabs.is-toggle li.is-active a {
  background-color: #f6ad43 !important;
  border-color: white !important;
}

// .tabs.is-toggle a {
//   color: white !important;
// }

.tabs.is-toggle a:hover {
  background-color: transparent !important;
  border-color: white !important;
}

.buttonCustom {
  background-color: #00286b !important;
  color: white !important;
  border: 1px solid #00286b !important;
}

.titleMain {
  font-size: 56px;
  font-style: italic;
  font-weight: 800;
  line-height: 64px;
  color: #002c77;
  margin-bottom: 20px;
}

.ticket {
  position: relative;
  background-color: white;
  color: black;
  text-transform: uppercase;
  padding: 1em 2em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.ticket:before,
.ticket:after {
  height: 200px;
  width: 45px;
  content: "";
  position: absolute;
  top: 3.9em;
  top: calc(3.1em - 1px);
  height: 2.2em;
  width: 1em;
  background-color: rgb(65 62 62);
}
.ticket:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: rgba(65, 62, 62, 0.84);
}
.ticket:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: rgba(65, 62, 62, 0.84);
}

.module {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  > h2 {
    padding: 1rem;
    margin: 0 0 0.5rem 0;
    margin-bottom: 0;
  }
  > p {
    padding: 0 1rem;
  }
}

.stripe-2 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #e8563d 10px,
      #e8563d 20px
    ),
    linear-gradient(to bottom, white, white);
}

.dashed-line {
  border-left: 3px dashed rgb(23 139 196 / 87%);
  height: 125px;
}

.headerRed {
  color: #e8563d;
  font-weight: 600;
}

.ticketRightSide {
  width: 25%;
}

.formsContainer {
  width: 100%;
  margin: auto;
}

.inputCol1 {
  width: 60%;
}

.inputCol2 {
  width: 40%;
}

.ticketLeftPart {
  width: 70%;
}

.addressForms {
  width: 80%;
  margin: auto;
}

.flexOne {
  flex: 1;
}

.flexTwo {
  flex: 2;
}

.width100 {
  width: 100% !important;
}

.flexGap {
  gap: 30px;
}

.width60 {
  width: 60%;
}

.width50 {
  width: 50%;
}

.width30 {
  width: 60% !important;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0;
  border: 1px solid white;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.tabs.is-toggle {
  border-radius: 0 !important;
}

.checkbox,
.radio:hover {
  color: white !important;
}

.fitcontent {
  width: fit-content;
}

.width20 {
  width: 22% !important;
}

.payBtn {
  background: #6e9e29 !important;
  padding: 20px 50px !important;
  border: 1px solid #6e9e29 !important;
}

.formBox {
  background: white;
  padding: 20px;
  color: black;
  margin-bottom: 30px;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bgBlue {
  background-color: #0885c0 !important;
}

.bgRed {
  background-color: #ce3e32 !important;
}

.stripe-1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #0885c0 10px,
      #0885c0 20px
    ),
    linear-gradient(to bottom, white, white);
}

.module1 {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  > h2 {
    padding: 3px;
    margin: 0 0 0.5rem 0;
    margin-bottom: 0;
  }
  > p {
    padding: 0 1rem;
  }
}

.radio:hover {
  color: black !important;
}

.background-tint {
  background-color: rgb(176 176 176 / 50%);
  background-blend-mode: multiply;
}

.fundraisingInput {
  width: 30%;
}

.select:not(.is-multiple):not(.is-loading)::after {
  z-index: 1 !important;
}

.custom-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-header-button {
  cursor: pointer;
  padding: 4px;
}

.custom-header-text {
  margin: 0 8px;
  font-weight: bold;
}

.inputBoxes {
  background: white;
  color: black;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.moduleCorrection {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.linkStyle {
  color: #4db1e7 !important;
  text-decoration: underline;
}

.sw-theme-arrows > ul.step-anchor {
  display: block;
  border: 0 solid #ccc !important;
  padding: 0;
  background: #f5f5f5 !important;
  border-radius: 5px;
  list-style: none;
  overflow: hidden;
  margin-top: 20px;
}

.sw-theme-arrows > ul.step-anchor > li.active > a {
  border-color: #5bc0de !important;
  color: #fff !important;
  background: #5bc0de !important;
}

.sw-theme-arrows > ul.step-anchor > li:first-child > a {
  padding-left: 15px;
}

.sw-theme-arrows > ul.step-anchor > li > a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 80px;
  margin-bottom: 20px;
}

.step {
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border: 2px solid #00286b;
  border-radius: 50%;
}

.filledCircle {
  background: #00286b;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #00286b;
}

.menu-container {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}

.tabs {
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  white-space: nowrap;
}

a::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
  // box-shadow: -1px 0 0 white;
  // transform: translateX(1px);
}

a::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--button-color-disabled, transparent);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
  // box-shadow: -1px 0 0 white;
  // transform: translateX(1px);
}

.tabs.is-toggle a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  padding: 8px 5px 8px 50px;
  border: 1px solid var(--button-color-disabled, transparent);
}

.tabs.is-toggle li:first-child a {
  padding: 8px 5px 8px 30px;
}

.tabs.is-toggle li:last-child a::after,
.tabs.is-toggle li:last-child a::before {
  border-left: none !important;
}

a {
  border-radius: 0 !important;
}

.tabs a {
  color: unset !important;
}

.is-disabled {
  color: #aeaeae73 !important;
  background-color: #434242 !important;
}

.inputStyle:focus {
  font-style: normal;
}

.inputStyle {
  line-height: 27px;
  font-size: 18px;
}

.emptyText {
  font-style: italic;
  color: #808080; /* Grey color */
}

.normalText {
  font-style: normal;
  color: #002c77 !important; /* Blue color */
}

.viewCampaign {
  font-size: 17px;
  cursor: pointer;
  text-decoration: underline;
  color: #002c77;
}

.is-disabled a {
  cursor: default !important;
}

.is-completed {
  background: #6e9e29 !important;
  color: white !important;
}

li.is-completed::after,
li.is-completed a::after {
  border-left-color: var(--button-color-completed, #6e9e29) !important;
}

li.is-active::after,
li.is-active a::after {
  border-left-color: var(--button-color-active, orange) !important;
}

.tabs.is-toggle a {
  border: none !important;
}

.tabs.is-toggle {
  border: 1px solid white;
}

.finalBtn {
  text-align: center;
  padding: 10px;
  background: #6e9e29;
  display: flex;
  justify-content: center;
}

.marginFinish {
  margin-top: -12px;
}

.finalPageContainer {
  background: white;
  color: black;
  border-radius: 10px;
  height: fit-content !important;
  padding-bottom: 40px;
}

a::after,
a::before {
  display: none;
}

.subheader3Error {
  color: #00286b;
  font-size: 22px;
  font-weight: 800;
  line-height: 33px;
  font-style: italic;
  text-align: center;
}

.backBtn {
  width: 40px;
  color: #00286b;
  border-radius: 50%;
  padding: 10px;
  height: 40px;
  border: 1px solid #00286b;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 120px;
  top: 10px;
  z-index: 1;
}

.formLabel {
  padding-bottom: 5px;
  font-size: 14px;
  color: #707070;
}

.formLabelXL {
  font-size: 18px;
  color: #707070;
}

.progressBarContainer {
  padding: 15px 40px 0 40px;
}

.progress::-webkit-progress-value {
  background-color: #74b022b0 !important;
}
.progress::-moz-progress-bar {
  background-color: #74b022b0 !important;
}

.progress::-ms-fill {
  background-color: #74b022b0 !important;
  border: none;
}

.deleteIcon {
  border: 2px solid #e8563d;
  border-radius: 50%;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.2rem !important;
}

.menuItemsSpacing {
  letter-spacing: 0.5px;
  font-size: 14px;
}

.react-calendar {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #dbdbdb !important;
  font-size: 14px;
  border-radius: 10px;
}

.react-calendar__tile--active {
  background: #1798dc !important;
  border-radius: 5px;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: "Asap", sans-serif !important;
}

.calendarContainer {
  width: fit-content;
}

.charityItem {
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1170px) {
  .background {
    background-position: top;
  }

  .containerWrapper {
    width: 100%;
  }

  .mainContainer {
    margin: auto;
    margin-top: 300px;
    width: 80%;
    margin-bottom: 100px;
  }
}

@media (min-width: 1025px) {
  .background {
    background-color: white !important;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hideScreen {
    display: none;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .mainContainer {
    margin: auto;
    margin-top: 100px;
    width: 100%;
  }

  .background {
    background-image: unset;
    display: block;
    background-color: #fcfbf9;
  }

  .mainContainer {
    margin-top: 0;
    width: 100%;
    background-color: #fcfbf9;
    box-shadow: none;
    padding: 20px;
    border-bottom: none;
  }

  .titleMain {
    margin-top: 45px;
  }

  .backBtn {
    display: none;
  }
}

@media (min-width: 768px) {
  .phone_mob {
    display: none;
  }
}

@media (max-width: 767px) {
  .mob-container {
    background-color: #fcfbf9;
  }

  .box-body {
    max-height: fit-content;
  }

  .phone_desk {
    display: none;
  }

  .containerFinal {
    justify-content: center;
  }

  .finalContainer {
    background: none;
    color: #00286b;
    padding: 0;
    width: 100%;
  }

  .finalSubheader {
    text-align: center;
  }

  .finalTxt {
    text-align: center;
  }

  .phone-img {
    position: relative;
    width: unset;
    height: unset;
    left: 0;
  }

  .mainsubheader_final {
    text-align: center;
  }

  .buttonCustom {
    margin-bottom: 30px;
  }

  .background {
    background-image: unset;
    display: block;
    background-color: #fcfbf9;
  }

  .mainContainer {
    margin-top: 0;
    width: 100%;
    background-color: #fcfbf9;
    box-shadow: none;
    padding: 20px;
    border-bottom: none;
  }

  .subheader {
    font-size: 20px;
    line-height: 30px;
  }

  .bgMobile {
    margin-top: 0 !important;
    height: 100vh;
    border-radius: 0;
  }

  .finalPageContainer {
    background-color: #fcfbf9;
  }

  .circle {
    width: 15px;
    height: 15px;
  }

  .progress-container {
    width: 60px;
  }

  .minsBtn {
    padding: 0;
  }

  .textareaStyle {
    height: 450px !important;
  }

  .text-body {
    font-size: 14px;
    line-height: 21px;
  }

  .form-label {
    font-size: 12px;
    line-height: 18px;
  }

  .titleMain {
    font-size: 24px;
    line-height: 22px;
  }

  .backBtn {
    display: none;
  }
}
